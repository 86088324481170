import "react-toastify/dist/ReactToastify.css";

import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import AllUsers from "./pages/AdminPages/AllUsers";
import Auth from "./pages/Auth";
import AuthContext from "./contexts/AuthCtx";
import CGU from "./pages/CGU";
import CGV from "./pages/CGV";
import Dashboard from "./pages/Dashboard";
import Expedition from "./pages/AdminPages/Expedition";
import Home from "./pages/Home";
import LegalInfo from "./pages/Certification/LegalProvision";
import Letter from "./pages/Letter/Letter";
import MentionsLegales from "./pages/mentions-legales";
import PC from "./pages/PC";
import PaymentError from "./pages/PaymentError/PaymentError";
import PaymentSuccess from "./pages/PaymentSuccess/PaymentSuccess";
import PersonalInfo from "./pages/Certification/PersonalInfo";
import { ToastContainer } from "react-toastify";
import { useContext } from "react";
import { useEffect } from "react";

function App() {
  const AuthCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  const { pathname } = location;

  const localPaths = ["/pc", "/ml", "/cgu", "/cgv"];

  useEffect(
    () => {
      if (
        !AuthCtx.userToken &&
        !AuthCtx.isLoggedIn &&
        !localPaths.includes(pathname)
      ) {
        navigate("/");
      }
    },
    // eslint-disable-next-line
    []
  );

  return (
    <>
      <ToastContainer />
      <Routes>
        <Route path="/pc" element={<PC />} />
        <Route path="/ml" element={<MentionsLegales />} />
        <Route path="/cgu" element={<CGU />} />
        <Route path="/cgv" element={<CGV />} />
        <Route path="/" element={<Home />} />
        <Route path="/auth/:token" element={<Auth />} />
        {AuthCtx.isLoggedIn && AuthCtx.userStatus !== "certified" && (
          <>
            <Route path="/certification" element={<PersonalInfo />} />
            <Route path="/certification/legal" element={<LegalInfo />} />
          </>
        )}

        {AuthCtx.isLoggedIn && AuthCtx.userStatus === "certified" && (
          <>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/letter/:selectedLetter" element={<Letter />} />
            <Route path="/success" element={<PaymentSuccess />} />
            <Route path="/error" element={<PaymentError />} />
          </>
        )}

        {AuthCtx.isAdmin && (
          <Route path="/admin/all-users" element={<AllUsers />} />
        )}
        {AuthCtx.isAdmin && <Route path="/admin" element={<AllUsers />} />}
        {AuthCtx.isAdmin && (
          <Route path="/admin/expedition" element={<Expedition />} />
        )}
      </Routes>
    </>
  );
}

export default App;
