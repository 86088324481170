import "react-multi-carousel/lib/styles.css";

import Carousel from "react-multi-carousel";
import React from "react";

const Testimonials = (props) => {
  const testimonials = [
    {
      id: 1,
      name: "Sophie M.",
      feedback:
        "J'ai toujours eu du mal à exprimer mes sentiments, surtout avec mes enfants. Lorsque j'ai découvert ByeByeRegrets, j'ai réalisé que je pouvais enfin leur dire tout ce que j'avais sur le cœur, même après ma mort. Écrire ces lettres m'a apporté une paix incroyable. Je me sens maintenant en paix, sachant que mes enfants recevront mes mots d'amour et de réconfort.",
    },
    {
      id: 2,
      name: "Marc T.",
      feedback:
        "Lorsque mon père est décédé, j'ai regretté de ne jamais l’avoir entendu me dire qu’il m’aimait. Avec ByeByeRegrets, j'ai pu écrire une lettre à ma fille pour lui expliquer mes sentiments et mes espoirs pour elle. C'était libérateur. Je suis convaincu que ces lettres seront un cadeau précieux pour elle quand je ne serais plus là.",
    },
    {
      id: 3,
      name: "Claire L.",
      feedback:
        "En tant que personne malade, j'ai toujours eu peur de ne pas avoir le temps de dire tout ce que je voulais. ByeByeRegrets m'a offert la possibilité d'écrire des lettres à mes proches. C'est comme une assurance que mes mots continueront de vivre, même après moi. Je recommande cette plateforme à tous ceux qui souhaitent laisser un héritage de pensées et d'émotions.",
    },
    {
      id: 4,
      name: "Alain k.",
      feedback:
        "Je suis un homme de peu de mots, mais j'ai beaucoup à dire. Grâce à ByeByeRegrets, j'ai pu exprimer mes regrets et mes espoirs. C'était un exercice cathartique, et je sais que ma famille pourra lire mes lettres quand le moment sera venu. Je me sens plus léger en sachant que mes sentiments seront transmis.",
    },
    {
      id: 5,
      name: "Émilie N.",
      feedback:
        "J'ai perdu ma mère récemment et j'aurais tant voulu qu'elle puisse me dire tout ce qu'elle avait sur le cœur. En découvrant ByeByeRegrets, j'ai commencé à écrire à mes amis et à ma famille. Je veux qu'ils sachent combien je les aime et ce que je pense d'eux. C'est une belle façon de tisser des liens et de partager des souvenirs.",
    },
    {
      id: 6,
      name: "Maxime S.",
      feedback:
        "Je suis quelqu'un de réservé et j'ai souvent du mal à exprimer mes émotions. Avec ByeByeRegrets, j'ai pu écrire à mon petit frère pour lui dire combien je l'aime. Ça m'a vraiment aidé à franchir ce cap, et je suis sûr qu'il sera touché de lire mes mots un jour.",
    },
    {
      id: 6,
      name: "Chloé M.",
      feedback:
        "À l'approche de mes 30 ans, je voulais prendre le temps de réfléchir à ma vie et à mes relations. Avec ByeByeRegrets, j'ai commencé à écrire des lettres à mes amis et ma famille pour leur dire ce que je ressens vraiment. Cela a transformé ma perspective sur la vie et l'importance des liens.",
    },
    {
      id: 6,
      name: "Samuel V.",
      feedback:
        "J’ai commencé à écrire des lettres à ma famille et mes amis pour leur dire combien je les aime et combien ils comptent pour moi. Même si tout va bien aujourd’hui, je préfère prendre les devants et m’assurer que mes mots leur parviennent, peu importe ce qui peut arriver demain.",
    },
  ];

  // React Multi Carousel responsive configuration
  const responsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 1615 }, items: 3 },
    desktop: { breakpoint: { max: 1615, min: 1024 }, items: 2.5 },
    tablet: { breakpoint: { max: 1024, min: 768 }, items: 1.5 },
    mobile: { breakpoint: { max: 768, min: 0 }, items: 1 },
  };

  return (
    <div id={props.id} className="w-full bg-[#F9E6E7] mt-[10vh] p-8">
      <h2 className="text-[#775454] text-2xl font-bold text-center underline italic mb-5">
        N’attendez pas qu’il soit trop tard, <br />
        exprimez-vous dès maintenant.
      </h2>
      <Carousel
        responsive={responsive}
        infinite
        autoPlay
        autoPlaySpeed={5000}
        showDots={false}
        arrows={true}
        className="rounded-lg"
      >
        {testimonials.map((testimonial) => (
          <div key={testimonial.id} className="p-4 h-full flex">
            <div className="bg-white p-6 shadow-md rounded-[70px] flex flex-col justify-between flex-grow">
              <p className="text-4xl text-primaryText font-amaranth font-bold mb-1 text-center">
                ”
              </p>
              <p className="text-[19px] font-josefin text-primaryText mb-4 flex-grow">
                {testimonial.feedback}
              </p>
              <p className="font-bold font-amaranth text-primaryText text-xl text-center">
                {testimonial.name}
              </p>
            </div>
          </div>
        ))}
      </Carousel>
      <div className="w-full flex justify-center mt-5">
        <button
          onClick={() => props.onScrollup()}
          className="bg-pink transition-all hover:bg-[#d47088] mx-auto lg:text-xl text-white font-josefin py-2 px-6 rounded-full self-center"
        >
          Commencer à écrire
        </button>
      </div>
    </div>
  );
};

export default Testimonials;
