import React from "react";

function HomeFooter() {
  return (
    <div className="flex bg-primaryBack flex-col md:flex-row justify-between items-center mt-10 py-5 p-1 md:p-10">
      <h1 className="text-2xl font-logo">ByeByeRegrets.</h1>
      <ul className="flex gap-5 md:gap-10 flex-wrap justify-center">
        {[
          {
            name: "Mentions Légales",
            link: "/ml",
          },
          {
            name: "CGU",
            link: "/cgu",
          },
          {
            name: "CGV",
            link: "/cgv",
          },
          {
            name: "Politique de Confidentialité",
            link: "/pc",
          },
        ].map((item) => (
          <li key={item.name}>
            <a
              href={item.link}
              className="text-primaryText text-xs md:text-base font-josefin"
            >
              {item.name}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default HomeFooter;
