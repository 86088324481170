import React, { useContext, useEffect, useState } from "react";
import {
  faCircleChevronDown,
  faCircleChevronUp,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";

import AuthContext from "../../contexts/AuthCtx";
import { ClipLoader } from "react-spinners";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Info } from "lucide-react";
import { Tooltip } from "react-tooltip";
import { api } from "../../api/api";
import envelope from "../UX/Icons/envelope.svg";
import mailbox from "../UX/Icons/mailbox.svg";
import moment from "moment";

function Outbox() {
  const [recipients, setRecipients] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showOutbox, setShowOutbox] = useState(false); // Control visibility
  const [isAnimating, setIsAnimating] = useState(false); // Control animation state
  const [vitalCheck, setVitalCheck] = useState(null);
  const AuthCtx = useContext(AuthContext);

  const getRecipientWithLetterTitle = async () => {
    setIsLoading(true);
    try {
      const data = await api.get("letter/getRecipientWithLetterTitle", {
        headers: {
          Authorization: `Bearer ${AuthCtx.userToken}`,
        },
      });
      setRecipients(data.data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const getVitalCheck = async () => {
    try {
      const data = await api.get("vital-check/", {
        headers: {
          Authorization: `Bearer ${AuthCtx.userToken}`,
        },
      });
      setVitalCheck(data.data);
    } catch (err) {
      console.error("Error", err);
    }
  };

  useEffect(() => {
    if (!AuthCtx.userToken) return;
    getRecipientWithLetterTitle();
    getVitalCheck();
  }, [AuthCtx.userToken]);

  const handleOutboxToggle = () => {
    if (showOutbox) {
      // Closing sequence: start animation and delay actual closing
      setIsAnimating(false); // Reset to close animation classes
      setTimeout(() => {
        setShowOutbox(false); // Hide component after animation completes
      }, 400); // Duration should match CSS transition time
    } else {
      // Opening sequence: Show component first, then start animation
      setShowOutbox(true);
      setTimeout(() => setIsAnimating(true), 10); // Trigger animation with slight delay
    }
  };

  return (
    <>
      <Tooltip id="infoToolTip" />
      <div className="mt-10 font-normal text-sm text-[#545454]">
        <div className="flex items-center gap-2">
          <p className="font-amaranth text-nowrap ">
            Dernière vérification des statuts vitaux :{" "}
            {vitalCheck && vitalCheck?.createdAt
              ? moment(vitalCheck?.createdAt).format("DD/MM/yyyy")
              : "-"}
          </p>

          <Info
            data-tooltip-id="infoToolTip"
            data-tooltip-html="Les informations fournies pour certifier votre compte<br /> sont utilisées périodiquement pour vérifier votre statut<br /> vital auprès des administrations publiques."
            className="cursor-pointer w-5"
          />
        </div>
        <div className="border shadow-md border-[#CAB9B9A6] rounded-md mb-10 flex flex-col gap-5 justify-center items-center w-full">
          <div className="flex items-center justify-between w-full bg-[#FFAEC2] p-5">
            <img src={mailbox} width={30} height={30} alt="Mail Box" />
            <button
              onClick={handleOutboxToggle}
              className="underline flex md:ml-20 gap-2 text-sm md:text-base md:pl-[3vw] items-center font-amaranth text-[#545454] font-medium"
            >
              Boîte d’envoi
              {!showOutbox && (
                <FontAwesomeIcon
                  className="text-pink"
                  icon={faCircleChevronDown}
                />
              )}
            </button>
            <div className="font-amaranth text-sm md:text-small">
              {recipients.length} exemplaire(s) à envoyer
            </div>
          </div>
          {showOutbox && (
            <div
              className={`transition-all duration-500 ease-in-out transform overflow-hidden w-full ${
                isAnimating
                  ? "max-h-[500px] opacity-100 translate-y-0"
                  : "max-h-0 opacity-0 -translate-y-4"
              }`}
            >
              {!isLoading && (
                <>
                  <p className="font-josefin text-[#545454] font-medium italic text-base text-center mt-4">
                    Ajoutez des destinataires à vos lettres pour créer des
                    exemplaires à leur envoyer.
                  </p>
                  <div className="overflow-x-auto w-full mt-5">
                    <table className="mb-5 border-separate border-spacing-y-4 w-full">
                      <thead className="bg-transparent font-amaranth text-black">
                        <tr>
                          <th className="px-6 py-3 font-amaranth text-md text-center font-medium">
                            Mode d’envoi
                          </th>
                          <th className="px-6 py-3 font-amaranth text-md text-center font-medium">
                            Exemplaire de la lettre
                          </th>
                          <th className="px-6 py-3 font-amaranth text-md text-center font-medium">
                            Destinataires
                          </th>
                          <th className="px-6 py-3 text-md text-center font-medium font-amaranth">
                            À envoyer...
                          </th>
                        </tr>
                      </thead>
                      <tbody className="w-full border border-[#CAB9B9A6] bg-[#F5F5F599]">
                        {recipients.length > 0 &&
                          recipients.map((item, index) => (
                            <tr
                              key={index}
                              className="bg-white font-josefin text-black "
                            >
                              <td className="px-6 py-4 text-center">
                                <img
                                  className="block m-auto"
                                  src={envelope}
                                  width={30}
                                  height={30}
                                  alt="Envelope"
                                />
                              </td>
                              <td className="px-6 py-4 font-amaranth text-center text-primaryText">
                                {item?.title}
                              </td>
                              <td className="px-6 py-4 text-center font-amaranth text-primaryText">
                                {item?.recipient?.slice(0, 20)}
                                {item?.recipient?.length > 20 ? "..." : ""}
                              </td>
                              <td className="px-6 py-4 font-amaranth text-center text-primaryText">
                                Après votre décès
                              </td>
                            </tr>
                          ))}
                        {!recipients.length > 0 && (
                          <tr>
                            <td
                              className="text-center font-amaranth text-[#54545499] font-medium h-[100px] border border-[#CAB9B9A6] bg-[#F5F5F599]"
                              colSpan={4}
                            >
                              La boîte d’envoi est vide.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
            </div>
          )}
          {showOutbox && (
            <div>
              <FontAwesomeIcon
                onClick={handleOutboxToggle}
                size="1x"
                className="text-pink cursor-pointer text-base"
                icon={faCircleChevronUp}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Outbox;
