import HomeFooter from "../components/UX/HomeFooter";
import HomeHeader from "../components/UX/HomeHeader";
import React from "react";

function CGU() {
  return (
    <div className="flex flex-col min-h-screen">
      <HomeHeader />
      <div className="max-w-4xl mx-auto p-8 font-josefin flex-1">
        <h1 className="text-3xl font-bold mb-6 text-[#775454] font-amaranth">
          Conditions Générales d’Utilisation (CGU)
        </h1>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4 text-[#775454] font-amaranth">
            1. Objet des CGU
          </h2>
          <p className="text-[#775454] font-josefin">
            Les présentes CGU définissent les règles d’utilisation du site
            ByeByeRegrets ainsi que des services associés. En utilisant le site,
            les utilisateurs acceptent sans réserve les présentes conditions.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4 text-[#775454] font-amaranth">
            2. Inscription et accès aux services
          </h2>
          <p className="text-[#775454] font-josefin">
            L’accès à certains services nécessite la création d’un compte
            personnel. L’utilisateur garantit l’exactitude des informations
            fournies lors de l’inscription et doit mettre à jour son profil en
            cas de modification. La création de compte est réservée aux
            personnes âgées d’au moins 15 ans et capables.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4 text-[#775454] font-amaranth">
            3. Services proposés
          </h2>
          <p className="text-[#775454] font-josefin">
            ByeByeRegrets permet aux utilisateurs de rédiger des lettres
            personnelles destinées à être envoyées post-mortem aux destinataires
            de leur choix. Le service inclut la sauvegarde des lettres et la
            gestion des destinataires. L’ajout de destinataires est soumis à
            l'achat de timbres virtuels.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4 text-[#775454] font-amaranth">
            4. Obligations des utilisateurs
          </h2>
          <p className="text-[#775454] font-josefin">
            Les utilisateurs s’engagent à respecter les règles suivantes :
          </p>
          <ul className="list-disc list-inside space-y-2">
            <li className="font-josefin text-[#775454]">
              Ne pas diffuser de contenus injurieux, diffamatoires, racistes, ou
              haineux.
            </li>
            <li className="font-josefin text-[#775454]">
              Utiliser des informations exactes pour les lettres et
              destinataires.
            </li>
            <li className="font-josefin text-[#775454]">
              Ne pas utiliser le site dans un cadre commercial, sauf
              autorisation écrite.
            </li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4 text-[#775454] font-amaranth">
            5. Modifications et résiliation
          </h2>
          <p className="text-[#775454] font-josefin">
            ByeByeRegrets se réserve le droit de modifier les CGU et en
            informera les utilisateurs. La société peut suspendre ou supprimer
            le compte d’un utilisateur en cas de non-respect des CGU.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4 text-[#775454] font-amaranth">
            6. Responsabilité
          </h2>
          <p className="text-[#775454] font-josefin">
            ByeByeRegrets s'engage à sécuriser les données et à fournir un
            service de qualité. Toutefois, la société ne pourra être tenue
            responsable de pertes de données, erreurs d’envoi, ou
            dysfonctionnements techniques.
          </p>
        </section>
      </div>
      <HomeFooter />
    </div>
  );
}

export default CGU;
