import { Link } from "react-router-dom";
import React from "react";

function HomeHeader() {
  return (
    <div className="z-[3] h-auto flex flex-col items-center p-5">
      {/* Header with Hamburger Button */}
      <div className="flex justify-center items-center w-full">
        <Link to="/">
          <h1 className="text-2xl font-normal font-logo">ByeByeRegrets.</h1>
        </Link>
        {/* Hamburger Button */}
      </div>
    </div>
  );
}

export default HomeHeader;
