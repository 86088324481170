import HomeFooter from "../components/UX/HomeFooter";
import HomeHeader from "../components/UX/HomeHeader";
import React from "react";

function PC() {
  return (
    <div className="flex flex-col min-h-screen">
      <HomeHeader />
      <div className="max-w-4xl mx-auto p-8 font-josefin flex-1">
        <h1 className="text-3xl font-bold mb-6 text-[#775454] font-amaranth">
          Politique de Confidentialité
        </h1>

        <section className="mb-6 font-josefin">
          <h2 className="text-2xl font-semibold mb-4 text-[#775454] font-amaranth">
            1. Données collectées
          </h2>
          <p className="mb-2 text-[#775454] font-josefin">Nous collectons les informations suivantes :</p>
          <ul className="list-disc list-inside space-y-2">
            <li className="font-josefin text-[#775454]">
              Informations personnelles : sexe, nom, prénom, adresse e-mail,
              date de naissance, commune de naissance, nationalité, adresse
              postale (si nécessaire).
            </li>
            <li className="font-josefin text-[#775454]">
              Informations sur les destinataires : sexe, nom, prénom, adresse
              postale.
            </li>
            <li className="font-josefin text-[#775454]">
              Informations techniques : adresse IP, navigateur, type d’appareil.
            </li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4 text-[#775454] font-amaranth">
            2. Finalité de la collecte
          </h2>
          <p className="mb-2 text-[#775454] font-josefin">Les données collectées sont utilisées pour :</p>
          <ul className="list-disc list-inside space-y-2">
            <li className="font-josefin text-[#775454]">
              Fournir le service de gestion et envoi de lettres post-mortem.
            </li>
            <li className="font-josefin text-[#775454]">
              Gérer le compte utilisateur et les paiements.
            </li>
            <li className="font-josefin text-[#775454]">
              Améliorer les fonctionnalités du site.
            </li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4 text-[#775454] font-amaranth">
            3. Base légale
          </h2>
          <p className="font-josefin text-[#775454]">
            La collecte de données est fondée sur le consentement explicite de
            l’utilisateur lors de la création de son compte et l’utilisation du
            service.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4 text-[#775454] font-amaranth">
            4. Conservation des données
          </h2>
          <p className="font-josefin text-[#775454]">
            Les données des utilisateurs sont conservées aussi longtemps que
            leur compte est actif. En cas de fermeture du compte, les données
            sont supprimées sous un délai raisonnable, sauf obligation légale de
            conservation.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4 text-[#775454] font-amaranth">
            5. Droits des utilisateurs
          </h2>
          <p className="mb-2 text-[#775454] font-josefin">
            Conformément au RGPD, les utilisateurs disposent des droits suivants
            :
          </p>
          <ul className="list-disc list-inside space-y-2">
            <li className="font-josefin text-[#775454]">
              Droit d’accès et de rectification : Ils peuvent accéder et
              modifier leurs données depuis leur espace personnel.
            </li>
            <li className="font-josefin text-[#775454]">
              Droit d’opposition : Les utilisateurs peuvent s’opposer à certains
              traitements de leurs données.
            </li>
            <li className="font-josefin text-[#775454]">
              Droit de suppression : Sur demande, leurs données peuvent être
              supprimées.
            </li>
            <li className="font-josefin text-[#775454]">
              Droit de portabilité : Sur demande, ils peuvent récupérer une
              copie de leurs données.
            </li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4 text-[#775454] font-amaranth">
            6. Partage des données
          </h2>
          <p className="font-josefin text-[#775454]">
            Les données des utilisateurs ne sont pas vendues ni échangées avec
            des tiers.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4 text-[#775454] font-amaranth">
            7. Sécurité des données
          </h2>
          <p className="font-josefin text-[#775454]">
            Nous mettons en place des mesures de sécurité adaptées pour protéger
            les données de nos utilisateurs. Toutefois, aucune transmission de
            données sur Internet n’est totalement sécurisée.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4 text-[#775454] font-amaranth">
            8. Utilisation des cookies
          </h2>
          <p className="font-josefin text-[#775454]">
            Le site utilise des cookies pour faciliter la navigation et
            améliorer l’expérience utilisateur. Les utilisateurs peuvent
            configurer leur navigateur pour désactiver les cookies, bien que
            cela puisse affecter certaines fonctionnalités.
          </p>
        </section>
      </div>
      <HomeFooter />
    </div>
  );
}

export default PC;
