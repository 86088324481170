import HomeFooter from "../components/UX/HomeFooter";
import HomeHeader from "../components/UX/HomeHeader";
import React from "react";

function MentionsLegales() {
  return (
    <div className="flex flex-col min-h-screen">
      <HomeHeader />
      <div className="max-w-4xl mx-auto p-8 font-josefin flex-1">
        <h1 className="text-3xl font-bold mb-6 text-[#775454] font-amaranth">
          Mentions Légales
        </h1>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4 text-[#775454] font-amaranth">
            1. Informations légales
          </h2>
          <p className="text-[#775454] font-josefin">
            Nom de la société : Byebyeregrets
          </p>
          <p className="text-[#775454] font-josefin">
            Forme juridique : SAS
          </p>
          <p className="text-[#775454] font-josefin">
            Capital social : 1000 euros
          </p>
          <p className="text-[#775454] font-josefin">
            Adresse du siège social : 8 bis route de Montflix, 91580, Villeconin
          </p>
          <p className="text-[#775454] font-josefin">
            Numéro SIRET : [Numéro SIRET]
          </p>
          <p className="text-[#775454] font-josefin">
            Numéro d’immatriculation au RCS : [RCS + ville]
          </p>
          <p className="text-[#775454] font-josefin">
            Responsable de la publication : Vincent PILLEUX
          </p>
          <p className="text-[#775454] font-josefin">
            Hébergeur du site : Amazon Web Services (AWS)
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4 text-[#775454] font-amaranth">
            2. Propriété intellectuelle
          </h2>
          <p className="text-[#775454] font-josefin">
            Tous les éléments du site ByeByeRegrets, qu’il s’agisse des textes, images, graphismes, logos, vidéos ou autres, sont protégés par le droit d’auteur, les marques ou les brevets. Toute reproduction, modification, distribution, ou exploitation sans autorisation est interdite et constitue une infraction passible de sanctions légales.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4 text-[#775454] font-amaranth">
            3. Limitation de responsabilité
          </h2>
          <p className="text-[#775454] font-josefin">
            ByeByeRegrets s’efforce d’assurer la fiabilité des informations présentes sur le site. Toutefois, la société ne saurait être tenue responsable en cas d’inexactitude, d’omission ou d’éventuels dommages liés à l’utilisation du site. Le site peut être temporairement inaccessible pour des raisons de maintenance ou de mises à jour.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4 text-[#775454] font-amaranth">
            4. Litiges et médiation
          </h2>
          <p className="text-[#775454] font-josefin">
            En cas de litige, l’utilisateur est invité à contacter le service client par e-mail : [email de contact]. En cas de litige persistant, l’utilisateur peut faire appel à un médiateur de la consommation avant de saisir le tribunal compétent.
          </p>
        </section>
      </div>
      <HomeFooter />
    </div>
  );
}

export default MentionsLegales;
