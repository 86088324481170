import * as XLSX from "xlsx";

import Html2Pdf from 'html-to-pdf-js';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { saveAs } from "file-saver";

const transformRecipientsData = (recipients) => {
  return recipients.map((recipient) => ({
    Title: recipient.title,
    FirstName: recipient.firstName,
    LastName: recipient.lastName,
    Country: recipient.country || "",
    PostalCode: recipient.postalCode || "",
    City: recipient.city || "",
    Locality: recipient.locality || "",
    Address: recipient.address || "",
    StreetAndName: recipient.stAndName || "",
  }));
};

const convertHTMLToFormattedText = (html) => {
  const tempElement = document.createElement("div");
  tempElement.innerHTML = html;

  const processNode = (node) => {
    if (node.nodeType === Node.ELEMENT_NODE) {
      const element = node ;
      const style = element.getAttribute("style") || "";
      const tagName = element.tagName.toLowerCase();
      const children = Array.from(element.childNodes).map(processNode).join("");

      let additionalStyles = "margin-bottom: 1em; "; // Add spacing between paragraphs

      // Handle text alignment
      if (element.style.textAlign) {
        additionalStyles += `text-align: ${element.style.textAlign};`;
      }

      // Handle font family
      if (element.style.fontFamily) {
        additionalStyles += `font-family: ${element.style.fontFamily};`;
      }

      // Handle text size with adjusted line heights
      if (element.classList.contains("body")) {
        additionalStyles += "font-size: 12px; line-height: 1.6; margin-bottom: 1.2em;";
      } else if (element.classList.contains("subtitle")) {
        additionalStyles += "font-size: 14px; line-height: 1.5; margin-bottom: 1.2em;";
      } else if (element.classList.contains("title")) {
        additionalStyles += "font-size: 18px; line-height: 1.4; margin-bottom: 1.5em;";
      }

      // Wrap text blocks in paragraph tags if they're not already
      if (tagName === 'div' && !element.querySelector('p')) {
        return `<p style="${style}${additionalStyles}">${children}</p>`;
      }

      return `<${tagName} style="${style}${additionalStyles}">${children}</${tagName}>`;
    } else if (node.nodeType === Node.TEXT_NODE) {
      const text = node.textContent || "";
      if (text.trim()) {
        return `<p style="margin-bottom: 1em;">${text}</p>`;
      }
    }
    return "";
  };

  return Array.from(tempElement.childNodes).map(processNode).join("").trim();
};

export const generatePDF = async (title, content) => {
  // Create a temporary container for the HTML
  const container = document.createElement("div");
  container.innerHTML = convertHTMLToFormattedText(content);
  container.style.boxSizing = "border-box";
  container.style.position = "relative";
  container.style.backgroundColor = "white";
  
  // Add custom styles for better page breaks
  const style = document.createElement('style');
  style.textContent = `
    p {
      margin-bottom: 1em;
      orphans: 3;
      widows: 3;
    }
    h1, h2, h3, h4, h5, h6 {
      break-after: avoid;
    }
  `;
  container.appendChild(style);
  
  // Add to DOM temporarily
  document.body.appendChild(container);

  // Configure PDF options
  const opt = {
    margin: 5,
    filename: `${title}.pdf`,
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { 
      scale: 2,
      useCORS: true,
      letterRendering: true
    },
    jsPDF: { 
      unit: 'mm', 
      format: 'a4', 
      orientation: 'portrait'
    },
    pagebreak: { 
      mode: ['avoid-all', 'css', 'legacy'],
      before: '.page-break-before',
      after: '.page-break-after',
      avoid: ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6']
    }
  };

  try {
    // Generate PDF
    await Html2Pdf(container, opt);
  } catch (error) {
    console.error('Error generating PDF:', error);
  } finally {
    // Clean up
    document.body.removeChild(container);
  }
};


export const generateXLSX = (data) => {
  const transformedRecipients = data.map((recipient, index) => ({
    RaisonSociale: "", // Assuming this is left empty
    "*Civilité (autorisée:'M.ou'  'Mme' )":
      recipient.title === "male" ? "M." : "Mme", // Assigning title based on index (0 for male, 1 for female)
    "*Nom": recipient.lastName,
    "*Prenom": recipient.firstName,
    "Complément d’adresse (bâtiment, appartement, étage, chez,…)":
      recipient.address,
    "*N° et libellé de voie": recipient.stAndName,
    "Lieu-dit/BP": recipient.locality,
    "*Code postal": recipient.postalCode,
    "*Ville": recipient.city,
    "*Pays": recipient.country,
  }));

  const ws = XLSX.utils.json_to_sheet(transformedRecipients);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Recipients");
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
  saveAs(blob, "recipients.xlsx");
};
