import React, { useContext, useEffect, useRef, useState } from "react";
import { faBars, faSpinner, faTimes } from "@fortawesome/free-solid-svg-icons";

import AuthContext from "../contexts/AuthCtx";
import FAQ from "../components/Home/FAQs";
import FeaturesSection from "../components/Home/Features";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HomeFooter from "../components/UX/HomeFooter";
import Testimonials from "../components/Home/Testimonial";
import { api } from "../api/api";
import home1 from "../assets/images/home1.webp";
import home2 from "../assets/images/home2.webp";
import home3 from "../assets/images/home3.webp";
import home4 from "../assets/images/home4.webp";
import home5 from "../assets/images/home5.webp";
import home6 from "../assets/images/home6.webp";
import homeR1 from "../assets/images/homeres1.webp";
import homeR2 from "../assets/images/homeres2.webp";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function Home() {
  const { isLoggedIn, userStatus } = useContext(AuthContext);
  const AuthCtx = useContext(AuthContext);
  const navigate = useNavigate();

  const inputRef = useRef();

  useEffect(() => {
    if (isLoggedIn && userStatus !== "certified") {
      navigate("/certification/legal");
    } else if (isLoggedIn && userStatus === "certified") {
      AuthCtx.updateHandler();
      navigate("/dashboard");
    }
  }, [isLoggedIn, userStatus, navigate, AuthCtx]);

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const sendLoginLinkHandler = async (e) => {
    e.preventDefault();
    // Validate email
    if (!validateEmail(email)) {
      toast.error("Email invalide");
      return;
    }
    inputRef.current.blur();
    setLoading(true);
    try {
      const response = await api.post("/auth", {
        email: email,
        url: process.env.REACT_APP_CLIENT_URL,
      });
      if (response.status === 200) {
        toast.success(
          "Un lien de connexion a été envoyé à votre adresse email"
        );
      }
      setLoading(false);
    } catch (err) {
      toast.error(err.response?.data?.message || "Une erreur est survenue");
      setLoading(false);
    }
  };

  const links = [
    { title: "Concept", link: "#concept" },
    { title: "Fonctionnalités", link: "#functionality" },
    { title: "Valeurs", link: "#values" },
    { title: "Témoignages", link: "#testimonial" },
    { title: "FAQ", link: "#faq" },
  ];

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [focus, setFocus] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    window.onscroll = () => {
      setIsMenuOpen(false);
    };
  }, []);

  useEffect(() => {
    inputRef.current.focus();
  }, [focus]);

  return (
    <div className="bg-[#F6E7E766]">
      {/* Initial Home Section */}
      <div
        id="home"
        className="flex flex-col lg:flex-row gap-2 h-[100vh] lg:h-auto mlgjustify-between"
      >
        <img
          src={home1}
          className="w-full hidden lg:block lg:w-[30vw] h-auto lg:h-[100vh] object-fill"
          alt="home1"
        />
        <img
          src={homeR1}
          className="w-full z-1 lg:hidden h-auto object-fit absolute"
          alt="home1"
        />
        <div className="grow z-[3] h-auto lg:h-[100vh] flex flex-col items-center p-5">
          {/* Header with Hamburger Button */}
          <div className="flex justify-between lg:justify-center items-center w-full">
            <h1 className="text-2xl font-normal font-logo">ByeByeRegrets.</h1>
            {/* Hamburger Button */}
            <button
              className="text-3xl lg:hidden focus:outline-none"
              onClick={toggleMenu}
            >
              <FontAwesomeIcon
                color="#545454"
                icon={isMenuOpen ? faTimes : faBars}
              />
            </button>
          </div>

          {/* Sliding Menu for Small Screens */}
          <div
            className={`absolute top-0 left-0 z-20 h-screen w-[70vw] bg-white lg:bg-transparent p-5 flex-col gap-5 justify-center transition-transform transform ${
              isMenuOpen ? "translate-x-0" : "-translate-x-full"
            } lg:static lg:transform-none lg:shadow-none lg:flex lg:flex-row lg:gap-5 lg:mt-[52px] lg:w-auto lg:h-auto lg:bg-transparent lg:p-0`}
          >
            <ul className="flex flex-col lg:flex-row gap-10 flex-wrap justify-center">
              {links.map((item) => (
                <li key={item.title}>
                  <a
                    className="font-montaga text-[#545454] xl:text-lg"
                    href={item.link}
                  >
                    {item.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="lg:mt-[22vh] 2xl:mt-[28vh] flex flex-col gap-5 items-center">
            <div className="absolute lg:static lg:top-auto top-[40%] flex flex-col items-center">
              <p className="text-3xl leading-8 lg:text-4xl 2xl:text-5xl w-[90vw] lg:w-9/12 font-amaranth text-primaryText text-center">
                Exprimez-vous dans des lettres,
                <br /> envoyées à vos proches après votre départ de ce monde
              </p>
              <form
                className="bg-[#F9E6E7] mt-10 transition-all focus-within:border focus-within:border-[#ff658a] w-[98vw] md:w-9/12 px-5 py-2 rounded-full flex flex-row gap-4"
                onSubmit={sendLoginLinkHandler}
              >
                <input
                  placeholder="Adresse e-mail"
                  value={email}
                  ref={inputRef}
                  className="bg-[#F9E6E7] font-josefin 2xl:text-lg p-2 outline-none placeholder-[#9B776DE5] text-[#9B776DE5] flex-1"
                  onChange={(e) => setEmail(e.target.value.toLowerCase())}
                />
                <button
                  className="bg-pink transition-all hover:bg-[#d47088] 2xl:text-lg flex-none text-white font-josefin rounded-full p-3 px-14"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? (
                    <FontAwesomeIcon
                      icon={faSpinner}
                      style={{
                        fontSize: "1rem",
                      }}
                      className="fa-spin"
                    />
                  ) : (
                    "Accéder"
                  )}
                </button>
              </form>
              <p className="font-josefin mt-3 text-primaryText text-md text-center">
                Accédez GRATUITEMENT à votre espace personnel
              </p>
            </div>
            <img
              src={homeR2}
              className="lg:hidden md:w-[400px] w-[200px] object-fit absolute bottom-0"
              alt="home1"
            />
          </div>
        </div>
      </div>

      {/* Second Section (e.g., Concept) */}
      <div className="w-full">
        {/* Conditionally Render Second Nav Bar */}
        {/* {inView && (
          <div className="bg-[#F9E6E740] z-[50] backdrop-blur-sm py-10 flex justify-center items-center sticky top-0">
            <div className="w-100 md:w-9/12 flex flex-col md:flex-row gap-3 md:gap-5 items-center justify-between">
              <h1 className="text-xl md:text-2xl font-logo">ByeByeRegrets.</h1>
              <ul className="flex items-center gap-5 flex-wrap justify-center">
                {links.map((item) => (
                  <li key={item.title}>
                    <a
                      className="text-sm md:text-base font-montaga"
                      href={item.link}
                    >
                      {item.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )} */}

        {/* Rest of the Home Content */}

        {/* Concept Section */}
        <div className="bg-[#FFDCDD] py-5">
          <div className="flex container mx-auto mt-0 p-5 gap-14 2xl:gap-32 items-center flex-col lg:flex-row justify-center">
            <div id="concept" className="w-full lg:w-1/2 flex flex-col gap-14">
              {[
                {
                  title: "1. Rédigez vos lettres sur ByeByeRegrets",
                  description:
                    "Prenez le temps d’exprimer vos sentiments. Ecrivez ce qui compte le plus pour vous, en toute liberté.",
                },
                {
                  title: "2. Indiquez à qui elles sont destinées",
                  description:
                    "Ajoutez les destinataires de vos lettres. Choisissez ceux qui recevront vos lettres quand le moment sera venu.",
                },
                {
                  title: "3. Nous veillerons à leur transmettre",
                  description:
                    "Aucune autre action n’est nécessaire de votre part. Vos lettres seront imprimées, scellées puis envoyées automatiquement par recommandé à leurs destinataires uniquement après votre décès.",
                },
              ].map((item) => (
                <div className="flex flex-col gap-3.5" key={item.title}>
                  <h3 className="font-amaranth text-2xl 2xl:text-[28px] font-bold text-primaryText">
                    {item.title}
                  </h3>
                  <p className="text-primaryText 2xl:text-[21px] 2xl:leading-7 font-josefin">
                    {item.description}
                  </p>
                </div>
              ))}
            </div>

            <motion.img
              className="w-full sm:w-1/2 xl:w-[500px] h-auto object-cover rounded-2xl cursor-pointer"
              src={home6}
              alt="Concept Illustration"
              whileHover={{ scale: 1.05 }} // Slight zoom effect
              transition={{ duration: 0.3 }}
            />
          </div>
        </div>
        <div className="w-full px-5 mt-0 py-10 bg-[#f7f0ec]">
          {/* Functionality Section */}
          <div
            id="functionality"
            className="mt-[10vh] container mx-auto flex flex-col gap-10 items-center justify-center"
          >
            <div className="flex flex-col justify-center gap-10 lg:flex-row 2xl:gap-[100px] ">
              {[
                {
                  title: "Rédigez vos lettres sans restriction",
                  image: home3,
                  description:
                    "Vous pouvez écrire tout ce que vous avez sur le cœur, sans aucune retenue. C'est votre espace pour vous exprimer pleinement. Vos sentiments sont importants, et vous avez la possibilité de tous les exprimer.",
                },
                {
                  title: "Modifiez vos écrits au fil du temps",
                  image: home4,
                  description:
                    "Adapter le contenu de vos lettres autant de fois que vous le souhaitez. Que ce soit pour exprimer de nouvelles réflexions, tenir compte des événements de la vie, ajuster vos sentiments, ou simplement corriger des détails.",
                },
                {
                  title: "Vos sentiments préservés indéfiniment",
                  image: home5,
                  description:
                    "Vous n'avez plus à vous inquiéter du temps qui passe. Chaque mot que vous écrivez est prêt à être transmis à vos proches quand le moment sera venu. Vivez pleinement sans vous soucier de vos sentiments inavoués.",
                },
              ].map((item) => (
                <div
                  key={item.title}
                  className="flex flex-col gap-8 items-center"
                >
                  <h3 className="font-amaranth xl:w-[90%] md:text-center text-2xl font-medium text-primaryText">
                    {item.title}
                  </h3>
                  <motion.img
                    whileHover={{ scale: 1.05 }} // Slight zoom effect
                    transition={{ duration: 0.3 }}
                    src={item.image}
                    alt={item.title}
                    className="w-full max-w-xs h-auto cursor-pointer"
                  />
                  <p className="font-josefin max-w-xs w-100 text-md text-primaryText text-left">
                    {item.description}
                  </p>
                </div>
              ))}
            </div>
            <button
              onClick={() => {
                setFocus((prev) => !prev);
                window.location.hash = "#home";
              }}
              className="bg-pink transition-all hover:bg-[#d47088] lg:text-xl text-white font-josefin py-2 px-6 rounded-full"
            >
              Écrire une lettre
            </button>
          </div>
        </div>

        <FeaturesSection />

        {/* Values Section */}
        <div
          id="values"
          className="flex  container mx-auto mt-0 p-5 gap-14 2xl:gap-32 items-center flex-col lg:flex-row justify-center"
        >
          <motion.img
            whileHover={{ scale: 1.05 }} // Slight zoom effect
            transition={{ duration: 0.3 }}
            className="w-full xl:w-[500px] sm:w-1/2 h-auto object-cover cursor-pointer"
            src={home2}
            alt="Values Illustration"
          />
          <div className="w-full md:w-1/2 flex flex-col gap-5">
            {[
              {
                title: "Engagement",
                description:
                  "Nous vous plaçons au cœur de tout ce que nous faisons, avec une attention particulière à la qualité, à la durabilité et à l'éthique de notre service.",
              },
              {
                title: "Confidentialité",
                description:
                  "Nous ne partagerons jamais vos informations personnelles sans votre consentement. Votre vie privée est notre priorité, et nous nous engageons à la protéger.",
              },
              {
                title: "Transparence",
                description:
                  "Votre statut vital nous est fourni par les autorités publiques. Cette information est utilisée exclusivement pour autoriser la livraison des lettres à leurs destinataires.",
              },
            ].map((item) => (
              <div className="flex flex-col gap-3.5" key={item.title}>
                <h3 className="font-amaranth text-2xl 2xl:text-[28px] font-bold text-primaryText">
                  {item.title}
                </h3>
                <p className="text-primaryText 2xl:text-[21px] 2xl:leading-7 font-josefin">
                  {item.description}
                </p>
              </div>
            ))}
          </div>
        </div>

        {/* Testimonials and FAQ */}
        <Testimonials
          onScrollup={() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
            setFocus((prev) => !prev);
          }}
          id="testimonial"
        />
        <FAQ id="faq" />

        {/* Footer */}
        <HomeFooter />
      </div>
    </div>
  );
}

export default Home;
