import ModalAnimationWrapper from "../UX/ModalAnimationWrapper";
import React from "react";
import mailbox from "../UX/Icons/mailbox.svg";
import styles from "./DeleteConfirmationModal.module.css";

function ReceipientAdded({ onClose }) {
  return (
    <div className={styles.container}>
      <ModalAnimationWrapper className="flex justify-center items-center w-full h-full">
        <div className="flex gap-5 items-center w-[500px] flex-col bg-white rounded-lg max-[482px]:w-[90vw]">
          <div className="bg-primaryBack rounded-t-lg text-primaryText w-full font-amaranth text-xl text-center p-3">
            Destinataire ajouté
          </div>
          <div className="flex gap-3 justify-center items-center">
            <img src={mailbox} width={200} height={200} alt="Mailbox Icon" />
          </div>
          <p className="text-grey font-josefin p-5 text-justify">
            Un exemplaire de votre lettre a été placé dans la{" "}
            <span className="font-bold">boîte d’envoi </span>
            et sera envoyé à ce destinataire à votre décès. L’exemplaire sera
            mis à jour si vous modifez le contenu de la lettre.
          </p>
          <div className="flex gap-3 justify-center items-center mb-5">
            <button
              onClick={onClose}
              className="bg-primaryText  hover:bg-[#3d3434] transition-all font-josefin text-white py-2 px-6 rounded-full"
            >
              D’accord
            </button>
          </div>
        </div>
      </ModalAnimationWrapper>
    </div>
  );
}

export default ReceipientAdded;
