import HomeFooter from "../components/UX/HomeFooter";
import HomeHeader from "../components/UX/HomeHeader";
import React from "react";

function CGV() {
  return (
    <div className="flex flex-col min-h-screen">
      <HomeHeader />
      <div className="max-w-4xl mx-auto p-8 font-josefin flex-1">
        <h1 className="text-3xl font-bold mb-6 text-[#775454] font-amaranth">
          Conditions Générales de Vente (CGV)
        </h1>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4 text-[#775454] font-amaranth">
            1. Objet des CGV
          </h2>
          <p className="text-[#775454] font-josefin">
            Les présentes CGV régissent les conditions de vente des timbres virtuels nécessaires à l’ajout de destinataires dans le service ByeByeRegrets.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4 text-[#775454] font-amaranth">
            2. Prix et paiement
          </h2>
          <p className="text-[#775454] font-josefin">
            Les prix des timbres sont indiqués sur le site en euros, toutes taxes comprises. Les paiements s’effectuent via une plateforme sécurisée (carte bancaire ou autres moyens indiqués).
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4 text-[#775454] font-amaranth">
            3. Droit de rétractation
          </h2>
          <p className="text-[#775454] font-josefin">
            Étant donné la nature des services, les achats de timbres virtuels sont définitifs et ne bénéficient pas du droit de rétractation. En cas de suppression de lettres ou de destinataires, les timbres sont rendus sur le compte de l’utilisateur pour une réutilisation.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4 text-[#775454] font-amaranth">
            4. Livraison des services
          </h2>
          <p className="text-[#775454] font-josefin">
            Les lettres seront envoyées aux destinataires désignés uniquement après le décès de l’utilisateur, une fois ce dernier confirmé. ByeByeRegrets ne garantit pas une livraison immédiate post-mortem en raison de vérifications nécessaires.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4 text-[#775454] font-amaranth">
            5. Limitation de responsabilité
          </h2>
          <p className="text-[#775454] font-josefin">
            ByeByeRegrets ne pourra être tenu responsable des erreurs de livraison dues à des informations incorrectes fournies par l’utilisateur. La société décline toute responsabilité si les lettres ne peuvent être transmises pour des raisons indépendantes de sa volonté.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4 text-[#775454] font-amaranth">
            6. Loi applicable et règlement des litiges
          </h2>
          <p className="text-[#775454] font-josefin">
            Les présentes CGV sont soumises au droit français. En cas de litige, l’utilisateur peut contacter le service client. Si aucune solution amiable n’est trouvée, le litige sera soumis au tribunal compétent.
          </p>
        </section>
      </div>
      <HomeFooter />
    </div>
  );
}

export default CGV;
