import "react-datepicker/dist/react-datepicker.css";

import React, { useContext, useState } from "react";
import { faChevronDown, faCircleInfo } from "@fortawesome/free-solid-svg-icons";

import AuthContext from "../../contexts/AuthCtx";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { api } from "../../api/api";
import { fr } from "date-fns/locale"; // Removed de import as it's unused
import moment from "moment";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function PersonalInfo() {
  const [title, setTitle] = useState("");
  const [nationality, setNationality] = useState("");
  const [firstName, setFirstName] = useState("");
  const [birthName, setBirthName] = useState("");
  const [birthNumber, setBirthNumber] = useState("");
  const [birthPlace, setPlaceBirth] = useState("");
  const [selectedDate, setSelectedDate] = useState(
    new Date("2000-01-01T00:00:00")
  );
  const [isLoading, setIsLoading] = useState(false);
  const ageRestriction = new Date().setFullYear(new Date().getFullYear() - 15);
  const AuthCtx = useContext(AuthContext);
  const navigate = useNavigate();

  const styles = {
    input:
      "px-2 py-3 w-full text-grey font-josefin border-[#D1D5DB] border outline-none rounded-md",
  };

  // Helper function to capitalize the first letter of each word

  const validateForm = () => {
    if (firstName.trim() === "") {
      toast.error("Le champ prénom est requis");
      return false;
    }
    if (birthName.trim() === "") {
      toast.error("Le champ nom est requis");
      return false;
    }
    if (!selectedDate) {
      toast.error("Le champ date de naissance est requis");
      return false;
    }
    if (nationality?.trim() === "") {
      toast.error("Veuillez sélectionner la nationalité");
      return false;
    }
    if (!title) {
      toast.error("Veuillez sélectionner le titre");
      return false;
    }
    if (birthNumber?.trim() === "") {
      toast.error("Veuillez saisir votre numéro de département de naissance");
      return false;
    }
    if (birthPlace?.trim() === "") {
      toast.error("Veuillez saisir le lieu de naissance");
      return false;
    }
    return true;
  };

  const submitFormHandler = async () => {
    const isValid = validateForm();
    if (!isValid) {
      return;
    }
    setIsLoading(true);
    const user_info = {
      firstName: firstName,
      birthName: birthName,
      nationality: nationality,
      title: title,
      date_of_birth: moment(selectedDate).format("YYYY-MM-DD"),
      birth_department_Number: birthNumber,
      place_of_birth: birthPlace,
    };
    const formData = new FormData();
    formData.append("user_info", JSON.stringify(user_info));
    try {
      await api.post("auth/updateUser", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      AuthCtx.updateHandler();
      navigate("/dashboard");
    } catch (err) {
      toast.error("Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-[#FAF1F1] min-h-[100vh]  flex justify-center items-center">
        <motion.div
          initial={{ x: "-100vw", opacity: 0 }} // Start off-screen to the left
          animate={{ x: 0, opacity: 1 }} // Slide to the center and become visible
          exit={{ x: "100vw", opacity: 0 }} // Slide out to the right (optional)
          transition={{ duration: 0.5, ease: "easeInOut" }}
        >
      <div className="w-[90vw] min-h-[100vh]  py-10 lg:w-[800px] lg:px-20 p-5 bg-white rounded-lg shadow-lg flex flex-col justify-center">
          <h3 className="self-center text-primaryText text-lg font-bold mb-5 font-amaranth">
            Création du compte - 2/2
          </h3>
          <h2 className="self-center text-primaryText text-3xl mb-5 2xl:mb-10 font-amaranth">
            Informations personnelles
          </h2>
          <div className="p-5 text-grey flex items-center gap-5 mb-5 2xl:gap-10 2xl:mb-10 border-2 border-pink rounded-md">
            <FontAwesomeIcon size="lg" color="#FF658A" icon={faCircleInfo} />
            <p className="text-[#545454]">
              Les informations renseignées ici doivent correspondre exactement à
              celles figurant sur vos documents d'identité. Elles seront
              utilisées pour vérifier périodiquement votre statut vital auprès
              des administrations publiques.
            </p>
          </div>
          <div className="form-group w-full flex flex-col gap-5">
            {/* Nationality */}
            <div className="relative">
              <select
                id="nationality"
                onChange={(e) => setNationality(e.target.value)}
                value={nationality}
                className={`appearance-none w-full p-3 pr-10 bg-white border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 ${
                  nationality === "Fr" ? "text-base" : "text-gray-400"
                }`}
              >
                <option value="" className="text-gray-400" disabled hidden>
                  Nationalité*
                </option>
                <option value="Fr">Française</option>
              </select>
              <span className="pointer-events-none absolute inset-y-0 right-3 flex items-center text-gray-400">
                <FontAwesomeIcon icon={faChevronDown} />
              </span>
            </div>

            {/* Title */}
            <div className="relative">
              <select
                id="title"
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                className={`appearance-none w-full p-3 pr-10 bg-white border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 ${
                  title === "" ? "text-gray-400" : "text-base"
                }`}
              >
                <option value="" disabled hidden>
                  Civilité*
                </option>
                <option value="M">Monsieur</option>
                <option value="F">Madame</option>
              </select>
              <span className="pointer-events-none absolute inset-y-0 right-3 flex items-center text-gray-400">
                <FontAwesomeIcon icon={faChevronDown} />
              </span>
            </div>

            {/* Birth Name */}
            <input
              required
              id="bName"
              type="text"
              placeholder="Nom de naissance*"
              value={birthName}
              className={styles.input}
              onChange={(e) => setBirthName(e.target.value)}
            />

            {/* First Name */}
            <input
              required
              id="fName"
              type="text"
              placeholder="Prénom*"
              className={styles.input}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />

            {/* Date of Birth and Birth Department */}
            <div className="flex flex-col md:flex-row gap-5 w-full">
              {/* Date of Birth */}
              <DatePicker
                placeholderText="Date de naissance*"
                name="dob"
                locale={fr}
                selected={selectedDate}
                dateFormat="dd/MM/yyyy"
                maxDate={ageRestriction}
                className={`${styles.input} !w-full`}
                onChange={(date) => setSelectedDate(date)}
                showYearDropdown
                scrollableMonthYearDropdown
                yearDropdownItemNumber={100}
                showMonthDropdown
                dropdownMode="select"
              />
              {/* Birth Department Number */}
              <div className="w-full" >
                <input
                  onChange={(e) => {
                    // Only allow positive numbers (and empty string to support clearing the field)
                    const value = e.target.value;
                    if (/^\d*$/.test(value)) {
                      setBirthNumber(value);
                    }
                  }}
                  onKeyDown={(e) => {
                    // Prevent e, E, -, + characters
                    if (
                      e.key === "e" ||
                      e.key === "E" ||
                      e.key === "-" ||
                      e.key === "+"
                    ) {
                      e.preventDefault();
                    }
                  }}
                  value={birthNumber}
                  aria-controls="null"
                  className={styles.input}
                  min="1"
                  type="number"
                  placeholder="N° département de naissance*"
                />
                <p className="text-right font-light text-[#54545499] text-sm" >Né à l’étranger : "99"</p>
              </div>
            </div>

            {/* Place of Birth */}
            <input
              onChange={(e) => setPlaceBirth(e.target.value)}
              value={birthPlace}
              type="text"
              className={styles.input}
              placeholder="Commune de naissance*"
            />

            {/* Button */}
            <button
              type="button"
              onClick={submitFormHandler}
              className={`p-2 bg-primaryText hover:bg-[#3d3434] transition-all w-[150px] text-white font-josefin rounded-3xl self-center  ${
                isLoading ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={isLoading}
            >
              {isLoading ? "Chargement..." : "Terminer"}
            </button>
          </div>
      </div>
        </motion.div>
    </div>
  );
}

export default PersonalInfo;
