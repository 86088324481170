import React from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

function LetterIcon({ title, id, content = "Nouvelle lettre" }) {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => {
        navigate(`/letter/${id}`);
      }}
      className="shadow-lg group md:mx-auto rounded-md cursor-pointer w-full h-[240px] md:w-[280px] md:h-[410px] overflow-hidden"
      whileHover={{ scale: 1.05 }} // Slight zoom effect
      transition={{ duration: 0.3 }} // Smooth transition
    >
      <div className="bg-primaryBack line-clamp-1 text-primaryText p-2 font-amaranth font-medium text-lg">
        {title?.slice(0, 25)}
        {title?.length > 25 && "..."}
      </div>
      <div
        className={`p-3 ${
          !(content === "Nouvelle lettre") &&
          `!text-[6.5px] [&_*]:!text-[6.5px]`
        } group-hover:scale-105 transition-all h-[350px] text-grey break-words line-clamp-[20] md:line-clamp-[35]`}
        dangerouslySetInnerHTML={{
          __html: `${content} `,
        }}
      ></div>
    </div>
  );
}

export default LetterIcon;
