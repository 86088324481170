import ModalAnimationWrapper from "../UX/ModalAnimationWrapper";
import React from "react";
import moment from "moment/moment";
import styles from "./BuyStampsModal.module.css";

function InformationModal({ onClose, user }) {
  return (
    <div className={styles.container}>
      <ModalAnimationWrapper className="flex justify-center items-center w-full h-full">
        <div className="flex overflow-auto gap-5 max-h-[80vh] items-center w-[500px] flex-col bg-white rounded-lg max-[482px]:w-[90vw]">
          <div className="bg-primaryBack rounded-t-lg text-primaryText w-full font-amaranth text-xl text-center p-3">
            Votre identité
          </div>
          <div>
            <p className="font-josefin text-primaryText text-lg">
              <span className="font-bold">Nationalité: </span>
              {user?.nationality}
            </p>
            <p className="font-josefin text-primaryText text-lg">
              <span className="font-bold">Civilité: </span>
              {user?.title === "M" ? "Monsieur" : "Madame"}
            </p>
            <p className="font-josefin text-primaryText text-lg">
              <span className="font-bold">Nom de naissance: </span>
              {user?.birthName}
            </p>
            <p className="font-josefin text-primaryText text-lg">
              <span className="font-bold">Prénom: </span>
              {user?.firstName}
            </p>
            <p className="font-josefin text-primaryText text-lg">
              <span className="font-bold">Date de naissance: </span>
              {moment(user?.date_of_birth?.split("T")[0]).format("DD-MM-YYYY")}
            </p>
            <p className="font-josefin text-primaryText text-lg">
              <span className="font-bold">Commune de naissance: </span>
              {user?.place_of_birth}
            </p>
            <p className="font-josefin text-primaryText text-lg">
              <span className="font-bold">N° département de naissance: </span>
              {user?.birth_department_Number}
            </p>
          </div>
          <div className="flex gap-3 justify-center items-center mb-5">
            <button
              onClick={onClose}
              className="w-full hover:bg-[#3d3434] transition-all bg-primaryText font-josefin  text-white py-2 px-6 rounded-full shadow-md disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Fermer
            </button>
          </div>
        </div>
      </ModalAnimationWrapper>
    </div>
  );
}

export default InformationModal;
