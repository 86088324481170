import { ClipLoader } from "react-spinners";
import ModalAnimationWrapper from "../UX/ModalAnimationWrapper";
import React from "react";
import { api } from "../../api/api";
import styles from "./TitleModal.module.css";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";

function TitleModal({ onClose, onFinish, isEditing, id }) {
  const titleRef = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState("");

  const finishHandler = () => {
    const title = titleRef.current.value;
    if (title.trim().length === 0) {
      toast.error("Entrez un titre valide");
      return;
    }
    titleRef.current.value = "";
    onFinish(title);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (isEditing) {
        setIsLoading(true);
        const data = await api.get(`letter/${id}`);
        setData(data.data.letter.title);
        setIsLoading(false);
      }
    };
    fetchData();
  }, [isEditing, id]);

  return (
    <div className={styles.container}>
      <ModalAnimationWrapper>
        <div className="flex gap-5 items-center w-[500px] flex-col bg-white rounded-lg max-[482px]:w-[90vw]">
          {!isLoading && (
            <>
              <div className="bg-primaryBack rounded-t-lg text-primaryText w-full font-amaranth text-xl text-center p-3">
                Modifier le titre
              </div>
              <input
                required
                ref={titleRef}
                id="letterTitle"
                maxLength={30}
                type="text"
                placeholder="Sans titre"
                className="text-grey w-2/3 p-3 border border-greyLight rounded-md outline-none"
                defaultValue={data}
              />
              <p className="text-grey font-josefin max-[580px]:p-3 ">
                Il ne sera pas connu des destinataires de la lettre.
              </p>
              <div className="flex gap-3 justify-center items-center mb-5  ">
                <button
                  onClick={finishHandler}
                  className="bg-primaryText  hover:bg-[#3d3434] transition-all font-josefin w-1/2 text-white py-2 px-6 rounded-full  "
                >
                  Terminer
                </button>
                <button
                  onClick={onClose}
                  className="bg-white font-josefin text-primaryText py-2 px-6 w-1/2 rounded-full shadow-md "
                >
                  Fermer
                </button>
              </div>
            </>
          )}
          <ClipLoader
            className={styles.loadingSpinner}
            loading={isLoading}
            color="#fff"
            size={30}
          />
        </div>
      </ModalAnimationWrapper>
    </div>
  );
}

export default TitleModal;
