import Feature1 from "../../assets/images/feature1.webp";
import Feature2 from "../../assets/images/feature2.webp";
import Feature3 from "../../assets/images/feature3.webp";
import Feature4 from "../../assets/images/feature4.webp";
import { motion } from "framer-motion";

export default function FeaturesSection() {
  const features = [
    {
      src: Feature1,
      title: "Double verification system",
      description: (
        <p className="font-josefin text-lg">
          Un système à double vérification en{" "}
          <span className="text-[#FF658A] font-josefin font-bold">
            collaboration avec les administrations publiques
          </span>
          , pour garantir l'exactitude de votre statut vital.
        </p>
      ),
      alignment: "right",
    },
    {
      src: Feature2,
      title: "Single purchase",
      description: (
        <p className="font-josefin text-lg">
          Un achat unique pour chaque destinataire de vos lettres,{" "}
          <span className="text-[#FF658A] font-josefin font-bold">
            sans abonnement ni frais supplémentaires.
          </span>
        </p>
      ),
      alignment: "left",
    },
    {
      src: Feature3,
      title: "Lifetime letter storage",
      description: (
        <p className="font-josefin text-lg">
          Conservation de vos lettres à vie dans le{" "}
          <span className="text-[#FF658A] font-josefin font-bold">
            respect total de la confidentialité
          </span>{" "}
          jusqu'à leur remise aux destinataires.
        </p>
      ),
      alignment: "right",
    },
    {
      src: Feature4,
      title: "Hand delivery",
      description: (
        <p className="font-josefin text-lg">
          <span className="text-[#FF658A] font-josefin font-bold">
            Remise de vos lettres en mains propres aux destinataires,
          </span>{" "}
          dans un délai de 1 à 2 mois après votre décès.
        </p>
      ),
      alignment: "left",
    },
  ];

  return (
    <section className="w-full bg-[#FAD1E980] mb-[10vh] py-16 md:py-24 px-4">
      <div className="container mx-auto max-w-2xl">
        <div className="space-y-10 md:space-y-20">
          {features.map((feature, index) => (
            <FeatureItem key={index} {...feature} />
          ))}
        </div>
      </div>
    </section>
  );
}

function FeatureItem({ src, title, description, alignment }) {
  const isRight = alignment === "right";

  return (
    <motion.div
      className={`flex flex-col ${
        isRight ? "md:flex-row-reverse" : "md:flex-row"
      } items-center justify-between gap-4`}
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5 }}
    >
      <div className="w-full md:w-1/2 space-y-4">
        <div className="text-[#775454]">{description}</div>
      </div>
      <motion.div
        className="relative w-48 h-48 md:w-64 md:h-64 flex-shrink-0 rounded-full bg-pink-100 p-4"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        <img
          src={src}
          alt={title}
          layout="fill"
          objectFit="cover"
          className="rounded-full"
        />
      </motion.div>
    </motion.div>
  );
}
