import React, { createContext, useEffect, useMemo, useState } from "react";

import { api } from "../api/api";

const AuthContext = createContext({
  userToken: null,
  isAdmin: false,
  isLoggedIn: false,
  userStatus: "not_certified",
  usedStamps: 0,
  totalStamps: 0,
  userName: "",
  login: (token, userData) => {},
  logout: () => {},
  updateHandler: () => {},
});

export const AuthContextProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("bbr_authLogin") === "true" || false
  );
  const token = localStorage.getItem("bbr_authUserToken");

  const [userToken, setUserToken] = useState(token);
  const [isAdmin, setIsAdmin] = useState(
    localStorage.getItem("bbr_isAdmin") === "true" || false
  );
  const [usedStamps, setUsedStamps] = useState(
    parseInt(localStorage.getItem("bbr_usedStamps"), 10) || 0
  );
  const [totalStamps, setTotalStamps] = useState(
    parseInt(localStorage.getItem("bbr_totalStamps"), 10) || 0
  );
  const [userStatus, setUserStatus] = useState(
    localStorage.getItem("bbr_userStatus") || "not_certified"
  );
  const [userName, setUserName] = useState(
    localStorage.getItem("bbr_userName") || ""
  );

  useEffect(() => {
    if (userToken) {
      api.defaults.headers.common["Authorization"] = `Bearer ${userToken}`;
    }
  }, [userToken]);

  const loginHandler = (
    token,
    role,
    status,
    totalStamps,
    usedStamps,
    user_info
  ) => {
    const fullName = `${user_info?.firstName || ""} ${user_info?.lastName || ""}`.trim();

    localStorage.setItem("bbr_authLogin", "true");
    localStorage.setItem("bbr_authUserToken", token);
    localStorage.setItem("bbr_userStatus", status);
    localStorage.setItem("bbr_totalStamps", totalStamps);
    localStorage.setItem("bbr_usedStamps", usedStamps);
    localStorage.setItem("bbr_userName", fullName);

    if (role === "admin") {
      localStorage.setItem("bbr_isAdmin", "true");
      setIsAdmin(true);
    } else {
      localStorage.setItem("bbr_isAdmin", "false");
      setIsAdmin(false);
    }

    setIsLoggedIn(true);
    setUserToken(token);
    setUserStatus(status);
    setTotalStamps(totalStamps);
    setUsedStamps(usedStamps);
    setUserName(fullName);
  };

  const logoutHandler = () => {
    localStorage.removeItem("bbr_authLogin");
    localStorage.removeItem("bbr_authUserToken");
    localStorage.removeItem("bbr_isAdmin");
    localStorage.removeItem("bbr_userStatus");
    localStorage.removeItem("bbr_totalStamps");
    localStorage.removeItem("bbr_usedStamps");
    localStorage.removeItem("bbr_userName");

    setIsAdmin(false);
    setIsLoggedIn(false);
    setUserToken(null);
    setUserStatus("not_certified");
    setUsedStamps(0);
    setTotalStamps(0);
    setUserName("");
  };

  const updateHandler = () => {
    api
      .get("auth/getUser", {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((data) => {
        const user = data.data.user;
        loginHandler(
          userToken,
          user.userRole,
          user.userStatus,
          user.tokens,
          user.usedTokens,
          user.user_info
        );
      })
      .catch((err) => {
        console.error("Error updating user", err);
      });
  };

  const value = useMemo(
    () => ({
      userToken,
      isLoggedIn,
      isAdmin,
      userStatus,
      usedStamps,
      totalStamps,
      userName,
      login: loginHandler,
      logout: logoutHandler,
      updateHandler,
    }),
    [userToken, isLoggedIn, isAdmin, userStatus, usedStamps, totalStamps, userName]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthContext;
